// src/components/Header.js
import React from 'react';
import '../styles/Footer.css';
import Insta from '../assets/images/insta.png';
import Etsy from '../assets/images/etsy.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-link">
        <a target='_blank' href="https://www.instagram.com/maf_tuft/" alt='insta' rel="noreferrer">
          <img alt='insta' src={Insta} />
          <p>@maf_tuft</p>
        </a>
      </div>
      <div className="footer-link">
        <a target='_blank' href="https://mafindustries.etsy.com" alt='insta' rel="noreferrer">
          <img alt='etsy' src={Etsy} />
          <p>Boutique</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
