import React, { useEffect, useState } from 'react';
import {
    Modal,
    Typography,
    IconButton,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox
} from '@mui/material';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';

const maxYarns = 26;
const maxHours = 8;

const unitPrices = {
    Yarns: 3,
    Cloth: 8,
    'Rug Glue': 12.9,
    'Anti-Slip Fabric': 21.99,
    'Spray Glue': 15,
    'Glue Gun': 0.25,
    'Tufting Gun Costs': 199.99,
    'Mower Costs': 55.99,
    'Working Hours': 5,
    'Edge Cover Strip': 17
};

const useMobileView = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobileView;
};

const TuftedRugModal = ({ open, onClose }) => {
    const isMobileView = useMobileView();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isShipping, setIsShipping] = useState(false);

    const useStyles = {
        modalStyle: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobileView ? '80%' : '450px', // Largeur ajustée pour mobile
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: 24,
            padding: '25px',
            maxHeight: isMobileView ? '95%' : 'auto', // Hauteur maximale ajustée pour mobile
            overflowY: 'auto' // Ajout d'un défilement si nécessaire
        },
        flexContainer: {
            display: 'flex'
        },
        title: {
            fontFamily: 'Alata, sans-serif',
            fontWeight: '400',
            fontSize: isMobileView ? '20px' : '30px',
            marginBottom: '20px'
        },
        closeicon: {
            margin: '0 0 auto auto'
        },
        sliderContainer: {
            width: '45%',
            margin: 'auto'
        },
        slider: {
            marginBottom: '20px'
        },
        tableStyle: {
            width: '80%',
            margin: 'auto'
        },
        checkboxContainer: {
            display: 'flex',
            marginTop: '20px'
        },
        checkboxText: {
            margin: ' auto auto auto 0'
        },
        svgStyle: {
            width: '25px'
        }
    };

    const handleWidthChange = (event, newValue) => {
        setWidth(newValue);
    };

    const handleHeightChange = (event, newValue) => {
        setHeight(newValue);
    };

    const handleShippingChange = (event) => {
        setIsShipping(event.target.checked);
    };

    const calculatePrice = (width, height) => {
        const area = width * height;
        const maxArea = 75 * 75;
        const areaPercentage = area / maxArea;

        // Calculate price for each item based on the specific formula
        const prices = Object.keys(unitPrices).map((key) => {
            let price = unitPrices[key] * areaPercentage; // Default formula

            // eslint-disable-next-line default-case
            switch (key) {
                case 'Yarns':
                    price =
                        Math.ceil(areaPercentage * maxYarns) * unitPrices[key];
                    break;
                case 'Rug Glue':
                    price = (areaPercentage * unitPrices[key]) / 4;
                    break;
                case 'Anti-Slip Fabric':
                    price = (areaPercentage * unitPrices[key]) / 4;
                    break;
                case 'Spray Glue':
                    price = (areaPercentage * unitPrices[key]) / 4;
                    break;
                case 'Glue Gun':
                    price = unitPrices[key] * 4;
                    break;
                case 'Edge Cover Strip':
                    price = (areaPercentage * unitPrices[key]) / 4;
                    break;
                case 'Tufting Gun Costs':
                    price = unitPrices[key] * 0.08;
                    break;
                case 'Mower Costs':
                    price = unitPrices[key] * 0.1;
                    break;
                case 'Working Hours':
                    price =
                        Math.ceil(areaPercentage * maxHours) * unitPrices[key];
                    break;
            }

            return {
                desc: key,
                price: price
            };
        });

        // Calculate the total price
        let totalPrice = prices.reduce((sum, item) => sum + item.price, 0);
        if (isShipping) {
            totalPrice += 15; // Add shipping cost if applicable
        }

        return { prices, totalPrice };
    };

    const { prices, totalPrice } = calculatePrice(width, height);

    return (
        <Modal open={open} onClose={onClose}>
            <div style={useStyles.modalStyle}>
                <div style={useStyles.flexContainer}>
                    <Typography style={useStyles.title}>
                        Estimate your tufted rug
                    </Typography>
                    <IconButton style={useStyles.closeicon} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div style={useStyles.flexContainer}>
                    <div style={useStyles.sliderContainer}>
                        <Typography gutterBottom>Width (cm)</Typography>
                        <Slider
                            style={useStyles.slider}
                            value={width}
                            onChange={handleWidthChange}
                            aria-labelledby='Width'
                            min={0}
                            max={75}
                            valueLabelDisplay='auto'
                        />
                    </div>
                    <div style={useStyles.sliderContainer}>
                        <Typography gutterBottom>Height (cm)</Typography>
                        <Slider
                            style={useStyles.slider}
                            value={height}
                            onChange={handleHeightChange}
                            aria-labelledby='Height'
                            min={0}
                            max={75}
                            valueLabelDisplay='auto'
                        />
                    </div>
                </div>
                <Table style={useStyles.tableStyle}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    padding: '6px' // Réduit le padding
                                }}
                            >
                                Description
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '6px' // Réduit le padding
                                }}
                                align='right'
                            >
                                Price
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prices.map((row) => (
                            <TableRow
                                key={row.desc}
                                sx={{
                                    height: 'auto', // Force la hauteur à être automatiquement ajustée
                                    '& > *': {
                                        borderBottom: 'unset', // Supprime les séparateurs
                                        padding: '6px',
                                        fontSize: '0.875rem' // Réduit la taille de la police si nécessaire
                                    }
                                }}
                            >
                                <TableCell
                                    component='th'
                                    scope='row'
                                    sx={{
                                        padding: '6px', // Réduit le padding
                                        height: 'auto', // Autorise la hauteur à s'ajuster au contenu
                                        borderBottom: 'none' // Supprime le séparateur
                                    }}
                                >
                                    {row.desc}
                                </TableCell>
                                <TableCell
                                    align='right'
                                    sx={{
                                        padding: '6px', // Réduit le padding
                                        height: 'auto', // Autorise la hauteur à s'ajuster au contenu
                                        borderBottom: 'none' // Supprime le séparateur
                                    }}
                                >
                                    {`${row.price.toFixed(2)}€`}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={useStyles.checkboxContainer}>
                    <Checkbox
                        style={useStyles.checkbox}
                        checked={isShipping}
                        onChange={handleShippingChange}
                    />
                    <Typography style={useStyles.checkboxText}>
                        Shipping (15€)
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <svg
                        style={useStyles.svgStyle}
                        width='36'
                        height='36'
                        viewBox='0 0 36 36'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <g clip-path='url(#clip0_79_952)'>
                            <path
                                d='M35.6019 16.28C33.0199 12.519 27.6819 18.054 17.9399 19.179C14.3329 19.595 11.0829 22.607 6.89289 22.32C5.17489 22.202 2.77589 17.259 0.97989 18.047C0.0558902 18.453 -0.36811 19.808 0.58189 20.826L11.1299 33.412C11.1299 33.412 11.6659 34.03 13.4719 33.602C13.9169 33.733 20.6069 31.052 24.4749 25.685C25.4009 24.4 26.5049 23.33 27.6479 22.436C31.3589 19.692 35.3929 18.736 35.3929 18.736C35.9089 18.31 36.0299 17.857 36.0019 17.464C36.0359 16.808 35.6019 16.28 35.6019 16.28Z'
                                fill='#5C913B'
                            />
                            <path
                                d='M11.4758 10.274C7.69281 15.524 1.06781 18.011 1.06781 18.011C-0.168188 19.058 0.875812 20.292 0.875812 20.292L11.3138 32.651C11.3138 32.651 11.8358 33.268 13.5938 32.84C13.5938 32.84 20.2188 30.358 24.0008 25.108C27.8998 19.698 34.9308 17.99 34.9308 17.99C36.1658 16.943 35.1228 15.707 35.1228 15.707L24.6848 3.34997C24.6848 3.34997 24.1628 2.73197 22.4048 3.15797C22.4048 3.15797 15.3748 4.86197 11.4758 10.274Z'
                                fill='#A7D28B'
                            />
                            <path
                                d='M22 19.5C25.0376 19.5 27.5 17.0376 27.5 14C27.5 10.9624 25.0376 8.5 22 8.5C18.9624 8.5 16.5 10.9624 16.5 14C16.5 17.0376 18.9624 19.5 22 19.5Z'
                                fill='#77B255'
                            />
                            <path
                                d='M12.8728 31.929C11.9918 31.929 11.6688 31.601 11.6248 31.551L2.21581 20.568C2.19781 20.547 1.72081 20.001 1.77881 19.307C1.81381 18.886 2.03181 18.511 2.42581 18.192L2.48881 18.154C2.54981 18.13 8.65181 15.622 12.0718 11.089C15.6898 6.29298 21.9308 4.23498 21.9928 4.21498C23.7378 3.80898 24.3088 4.38898 24.3698 4.45698L33.6548 15.501C33.6718 15.519 34.1478 16.067 34.0898 16.762C34.0548 17.182 33.8368 17.557 33.4428 17.876L33.3438 17.925C33.2828 17.94 27.2148 19.448 23.6998 24.106C20.2008 28.746 14.0928 31.748 14.0318 31.777C13.5678 31.887 13.1858 31.929 12.8728 31.929ZM12.0018 31.222C12.0028 31.222 12.4538 31.638 13.8668 31.31C13.8748 31.299 19.8758 28.348 23.3028 23.806C26.7398 19.251 32.5278 17.624 33.1698 17.455C33.4268 17.234 33.5688 16.989 33.5918 16.726C33.6338 16.246 33.2798 15.833 33.2768 15.829L23.9918 4.78398C23.9898 4.78398 23.5418 4.36898 22.1278 4.69698C22.0868 4.71098 15.9928 6.72299 12.4718 11.39C9.10581 15.851 3.23281 18.385 2.71381 18.603C2.44581 18.828 2.29981 19.079 2.27681 19.348C2.23681 19.824 2.58881 20.235 2.59181 20.239L12.0018 31.222Z'
                                fill='#5C913B'
                            />
                            <path
                                d='M21.276 22.166L21.27 28.106L21.767 28.696C22.736 27.812 23.667 26.818 24.472 25.7C25.281 24.586 26.2316 23.5822 27.3 22.714L26.817 22.139L21.276 22.166Z'
                                fill='#FFAC33'
                            />
                            <path
                                d='M26.7979 22.118L14.2919 7.30499C13.2759 8.14099 12.2999 9.11599 11.4699 10.269C10.6589 11.395 9.71486 12.386 8.73486 13.26L10.1969 14.991L10.1959 14.992L21.2709 28.106C22.2853 27.2067 23.199 26.1998 23.9959 25.103C24.8259 23.951 25.8009 22.977 26.8159 22.141L26.7979 22.118Z'
                                fill='#FFE8B6'
                            />
                            <path
                                d='M14.4791 22.555C12.9221 20.711 9.62606 23.703 8.68606 22.59C8.23106 22.051 8.52406 21.352 9.12206 20.848C10.1271 19.998 10.8521 20.493 11.3071 20.108C11.6301 19.836 11.6131 19.503 11.4211 19.274C10.9751 18.747 9.83506 19.022 8.94906 19.534L8.51806 19.024C8.38714 18.8782 8.20461 18.7891 8.00914 18.7756C7.81368 18.7621 7.62062 18.8252 7.47087 18.9515C7.32112 19.0779 7.22643 19.2576 7.20687 19.4525C7.18731 19.6475 7.2444 19.8424 7.36606 19.996L7.81206 20.525C7.13506 21.423 6.90506 22.615 7.70606 23.562C9.20206 25.332 12.5391 22.39 13.5891 23.634C13.9531 24.066 13.8511 24.89 13.0851 25.536C11.9371 26.507 10.8971 26.052 10.4301 26.446C10.2021 26.637 10.1611 27.001 10.4041 27.29C10.7911 27.747 12.0241 27.649 13.2091 26.911C13.2111 26.913 13.2111 26.915 13.2111 26.917L13.6981 27.493C13.7609 27.5721 13.8391 27.6377 13.9278 27.6861C14.0165 27.7344 14.114 27.7645 14.2146 27.7744C14.3151 27.7844 14.4166 27.7741 14.5131 27.7441C14.6096 27.7141 14.6991 27.6651 14.7763 27.5999C14.8534 27.5347 14.9168 27.4546 14.9624 27.3645C15.0081 27.2744 15.0353 27.176 15.0422 27.0752C15.0492 26.9745 15.0359 26.8733 15.0031 26.7777C14.9702 26.6822 14.9185 26.5942 14.8511 26.519L14.3641 25.943C14.3591 25.937 14.3511 25.933 14.3451 25.927C15.1441 24.949 15.4141 23.66 14.4791 22.555Z'
                                fill='#5C913B'
                            />
                        </g>
                        <defs>
                            <clipPath id='clip0_79_952'>
                                <rect width='36' height='36' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>

                    <Typography variant='h6'>
                        Approx. Value: {totalPrice.toFixed(2)}€
                    </Typography>
                </div>
                <Typography
                    style={{
                        fontSize: isMobileView ? '9px' : '12px',
                        textAlign: 'right'
                    }}
                >
                    This price is an estimation. The real price can differ from
                    it.
                </Typography>
                <div
                    style={{
                        marginTop: '20px',
                        textAlign: 'right',
                        display: 'flex'
                    }}
                >
                    <Typography
                        style={{
                            marginBottom: '20px',
                            fontSize: '12px',
                            margin: 'auto 10px auto auto'
                        }}
                    >
                        Interested? Send your project idea to me by email! I
                        will contact you asap to discuss about it!
                    </Typography>
                    <Button
                        variant='outlined'
                        startIcon={<EmailIcon />}
                        style={{
                            width: '210px'
                        }}
                        onClick={() => {
                            const email = 'lucasmafrica57@gmail.com';
                            const subject = encodeURIComponent(
                                'Tufted Rug Project Idea'
                            );
                            const body = encodeURIComponent(
                                `Hello,\n\n` +
                                    `I am interested in creating a custom tufted rug. Here are the details:\n` +
                                    `Width: ${width} cm\n` +
                                    `Height: ${height} cm\n` +
                                    `${
                                        isShipping ? 'Shipping : 15€\n\n' : ''
                                    }` +
                                    `Estimated Total Price: €${totalPrice.toFixed(
                                        2
                                    )}\n\n` +
                                    `Please contact me to discuss this project further.\n\n` +
                                    `Best regards,`
                            );

                            window.location = `mailto:${email}?subject=${subject}&body=${body}`;
                        }}
                    >
                        Send Mail
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default TuftedRugModal;
