// src/components/ProductsSection.js
import React, { useEffect, useRef } from 'react';
import '../styles/ProductsSection.css';
import lavaLamp from '../assets/images/rugs/lavalamp.png';
import lavaLamp2 from '../assets/images/rugs/lavalamp2.jpeg';
import eye from '../assets/images/rugs/eye.png';
import eye2 from '../assets/images/rugs/eye2.jpg';
import papillon from '../assets/images/rugs/papillon.png';
import papillon2 from '../assets/images/rugs/papillon2.jpeg';
import papillon3 from '../assets/images/rugs/papillon3.jpeg';
import mauve from '../assets/images/rugs/mauve.png';
import necron from '../assets/images/rugs/necron.png';
import daruma from '../assets/images/rugs/daruma.png';
import daruma2 from '../assets/images/rugs/daruma2.jpeg';
import daruma3 from '../assets/images/rugs/daruma3.jpeg';
import clavier from '../assets/images/rugs/clavier.png';
import clavier2 from '../assets/images/rugs/clavier2.jpeg';
import totoro from '../assets/images/rugs/totoro.png';
import bouftou from '../assets/images/rugs/bouftou.jpg';
import cactus from '../assets/images/rugs/cactus.jpg';
import cactus2 from '../assets/images/rugs/cactus2.jpeg';
import cactus3 from '../assets/images/rugs/cactus3.jpeg';
import socut from '../assets/images/rugs/socut.jpeg';
import socut2 from '../assets/images/rugs/socut2.jpeg';
import flowers1 from '../assets/images/rugs/flowers1.jpg';
import flowers2 from '../assets/images/rugs/flowers2.jpg';
import flowers3 from '../assets/images/rugs/flowers3.jpg';
import { ProductCard } from 'maf-react-components';

const ProductsSection = () => {
    const parallaxContainerRef = useRef(null);
    const productsSectionRef = useRef(null);

    useEffect(() => {
        if (parallaxContainerRef.current && productsSectionRef.current) {
            const productsHeight = productsSectionRef.current.offsetHeight;
            parallaxContainerRef.current.style.height = `${productsHeight}px`;
        }
    }, []);

    const products = [
        {
            title: 'LAMPE A LAVE',
            price: '80,00',
            imageUrl: [lavaLamp, lavaLamp2],
            width: '77',
            height: '33',
            isSold: false,
            linkCart:
                'https://www.etsy.com/fr/listing/1657579231/tapis-touffete-lampe-a-lave'
        },
        {
            title: 'L’œil',
            price: '45,00',
            imageUrl: [eye, eye2],
            width: '30',
            height: '30',
            isSold: false,
            linkCart:
                'https://www.etsy.com/fr/listing/1650233673/tapis-touffete-loil'
        },
        {
            title: 'Papillon',
            price: '60,00',
            imageUrl: [papillon, papillon2, papillon3],
            width: '75',
            height: '50',
            isSold: false,
            linkCart:
                'https://www.etsy.com/fr/listing/1650232141/tapis-touffete-papillon'
        },
        {
            title: 'L’œil mystique',
            price: '40,00',
            imageUrl: mauve,
            width: '75',
            height: '35',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Symbole Necron',
            price: '60,00',
            imageUrl: necron,
            width: '70',
            height: '35',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Daruma',
            price: '55,00',
            imageUrl: [daruma, daruma2, daruma3],
            width: '36',
            height: '36',
            isSold: false,
            linkCart:
                'https://www.etsy.com/fr/listing/1670289261/tapis-touffete-daruma'
        },
        {
            title: 'Tapis de Clavier',
            price: '45,00',
            imageUrl: [clavier, clavier2],
            width: '36',
            height: '16',
            isSold: false,
            linkCart:
                'https://www.etsy.com/fr/listing/1670287043/tapis-touffete-tapis-de-clavier'
        },
        {
            title: 'Totoro',
            price: '50,00',
            imageUrl: totoro,
            width: '70',
            height: '40',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Bouftou',
            price: '55,00',
            imageUrl: bouftou,
            width: '70',
            height: '70',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Cactus Paysage',
            price: '70,00',
            imageUrl: [cactus, cactus2, cactus3],
            width: '53',
            height: '46',
            isSold: false,
            linkCart:
                'https://www.etsy.com/fr/listing/1676451745/tapis-touffete-paysage-cactus'
        },
        {
            title: 'Logo SoCut',
            price: '0,00',
            imageUrl: [socut, socut2],
            width: '70',
            height: '55',
            isSold: true,
            linkCart: ''
        },
        {
            title: 'Parterre floral',
            price: '0,00',
            imageUrl: [flowers1, flowers2, flowers3],
            width: '70',
            height: '70',
            isSold: true,
            linkCart: ''
        }
    ];
    return (
        <div className='big-container'>
            <div ref={productsSectionRef} className='products-section'>
                {products.map((product, index) => (
                    <ProductCard key={index} {...product} />
                ))}
            </div>
        </div>
    );
};

export default ProductsSection;
