// src/components/Header.js
import React from 'react';
import logo from '../assets/images/logoFree.png';
import leaf1 from '../assets/images/leaves/leaf1.svg';
import leaf2 from '../assets/images/leaves/leaf2.svg';
import leaf3 from '../assets/images/leaves/leaf3.svg';
import leaf4 from '../assets/images/leaves/leaf4.svg';
import leaf5 from '../assets/images/leaves/leaf5.svg';
import leaf6 from '../assets/images/leaves/leaf6.svg';
import leaf7 from '../assets/images/leaves/leaf7.svg';
import midground from '../assets/images/pelotes.svg';
import foreground from '../assets/images/perso.png';
import '../styles/Header.css';
import { ParallaxBanner } from 'react-scroll-parallax';

const Header = () => {
    return (
        <header className='header'>
            <ParallaxBanner
                layers={[
                    {
                        image: midground,
                        speed: -30,
                        className: 'layer-midground'
                    },
                    {
                        image: foreground,
                        speed: -10,
                        className: 'layer-foreground'
                    }
                ]}
                className='parallaxBanner'
            >
                <div className='header-leaf-container'>
                    <img
                        src={leaf1}
                        alt='leaf1'
                        className='header-leaf header-leaf-1'
                    />
                    <img
                        src={leaf2}
                        alt='leaf2'
                        className='header-leaf header-leaf-2'
                    />
                    <img
                        src={leaf3}
                        alt='leaf3'
                        className='header-leaf header-leaf-3'
                    />
                    <img
                        src={leaf4}
                        alt='leaf4'
                        className='header-leaf header-leaf-4'
                    />
                    <img
                        src={leaf5}
                        alt='leaf5'
                        className='header-leaf header-leaf-5'
                    />
                    <img
                        src={leaf6}
                        alt='leaf6'
                        className='header-leaf header-leaf-6'
                    />
                    <img
                        src={leaf7}
                        alt='leaf7'
                        className='header-leaf header-leaf-7'
                    />
                </div>
                <div className='header-top'>
                    <img src={logo} alt='Logo' className='header-logo' />
                    <h1 className='header-title'>MAF INDUSTRIES</h1>
                </div>
            </ParallaxBanner>
        </header>
    );
};

export default Header;
