// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LinksPage from './pages/LinksPage'; // Assurez-vous de créer ce composant
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
    return (
        <Router>
            <ParallaxProvider>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/links' element={<LinksPage />} />
                </Routes>
            </ParallaxProvider>
        </Router>
    );
}

export default App;
