// src/HomePage.js
import React, { useState } from 'react';
import '../styles/App.css';
import Header from '../components/Header';
import ProductsSection from '../components/ProductsSection';
import Footer from '../components/Footer';
import TuftedRugModal from '../components/TuftedRugModal';
import { Button } from '@mui/material';

function HomePage() {
    const [isModalOpen, setIsModalOpen] = useState(false); // Ajout d'un état pour contrôler la visibilité de la modal

    const handleOpenModal = () => {
        setIsModalOpen(true); // Fonction pour ouvrir la modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Fonction passée à TuftedRugModal pour fermer la modal
    };
    return (
        <div className='App'>
            <Header />
            <div className='produits'>
                <ProductsSection />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '20px'
                    }}
                >
                    <Button variant='contained' onClick={handleOpenModal}>
                        Estimate your tufted rug
                    </Button>
                </div>
            </div>
            <TuftedRugModal open={isModalOpen} onClose={handleCloseModal} />
            <Footer />
        </div>
    );
}

export default HomePage;
